import React, { useRef, useEffect, useState } from 'react';

const LoopingVideo = ({ src }) => {
  const videoRef = useRef(null);
  const [isForward, setIsForward] = useState(true);
  const animationRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    const handlePlayback = () => {
      cancelAnimationFrame(animationRef.current);

      if (isForward) {
        video.playbackRate = 1; // Vorwärts
        video.play();
      } else {
        // Rückwärtswiedergabe simulieren
        video.pause();
        const stepBackward = () => {
          if (video.currentTime > 0) {
            video.currentTime -= 0.02; // Etwa 30 fps (1/30 Sekunde)
            animationRef.current = requestAnimationFrame(stepBackward);
          } else {
            togglePlaybackDirection(); // Richtung wechseln, wenn Anfang erreicht
          }
        };
        stepBackward();
      }
    };

    handlePlayback();

    return () => cancelAnimationFrame(animationRef.current);
  }, [isForward]);

  const togglePlaybackDirection = () => {
    setIsForward(!isForward);
  };

  const handleVideoEnd = () => {
    togglePlaybackDirection();
  };

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    video.addEventListener('ended', handleVideoEnd);

    return () => video.removeEventListener('ended', handleVideoEnd);
  }, []);

  return (
    <video
      ref={videoRef}
      muted
      playsInline
      className="h-full w-full object-cover object-center"
      autoPlay
    >
      <source src={src} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  );
};
export default LoopingVideo;
