import { Box, useTheme } from '@mui/material';
import InfoSection from '../../../components/InfoSection';
import InfoGalery from '../../../components/InfoGalery';
import { tokens } from '../../../theme/theme';

export const Baubegleitung = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const bullets = [
    'Professionelle Unterstützung in jeder Bauphase',
    'Begleitung durch einen zertifizierten Energieberater',
    '50 % Zuschuss für Baubegleitung durch die KfW/BAFA',
  ];
  const pictures = [
    {
      webp: '/media/energy/PFLEGER BAUSERVICE ENERGY-05.webp',
      jpg: '/media/energy/PFLEGER BAUSERVICE ENERGY-05.jpg',
    },
    {
      webp: '/media/energy/PFLEGER BAUSERVICE ENERGY FILL.webp',
      jpg: '/media/energy/PFLEGER BAUSERVICE ENERGY FILL.jpg',
      opacity: 0.2,
    },
  ];

  return (
    <Box className="h-full w-full flex flex-col xl:flex-row justify-between items-center p-2 md:p-10 overflow-hidden gap-4">
      <InfoSection
        number={3}
        title="Fachplanung & Baubegleitung"
        subTitle="Ihre Garantie für ein solides Bauprojekt."
        bulletArray={bullets}
        color={colors.energy[500]}
      />
      <InfoGalery pictures={pictures} />
    </Box>
  );
};
