import { Box, useTheme } from '@mui/material';
import InfoSection from '../../../components/InfoSection';
import InfoGalery from '../../../components/InfoGalery';
import { tokens } from '../../../theme/theme';

export const Foerdermittel = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const bullets = [
    'Wir finden die besten Förderkonditionen für Sie',
    'Übernahme der gesamten Fördermittelbeantragung',
    'Sorgenfreie Sanierung',
    'Individuelle Unterstützung',
  ];
  const pictures = [
    {
      webp: '/media/energy/PFLEGER BAUSERVICE ENERGY-01.webp',
      jpg: '/media/energy/PFLEGER BAUSERVICE ENERGY-01.jpg',
    },
    {
      webp: '/media/energy/PFLEGER BAUSERVICE ENERGY FILL.webp',
      jpg: '/media/energy/PFLEGER BAUSERVICE ENERGY FILL.jpg',
      opacity: 0.2,
    },
  ];

  return (
    <Box className="h-full w-full flex flex-col xl:flex-row justify-between items-center p-2 md:p-10 overflow-hidden gap-4">
      <InfoSection
        number={4}
        title="Fördermittelbeantragung"
        subTitle="Ihre Garantie für ein solides Bauprojekt."
        bulletArray={bullets}
        color={colors.energy[500]}
      />
      <InfoGalery pictures={pictures} />
    </Box>
  );
};
