import { Box, useTheme } from '@mui/material';
import { tokens } from '../../theme/theme';
import { useNavigate } from 'react-router-dom';

const NavItem = ({ item, active, onLocationChange = () => {}, color }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const navigate = useNavigate();

  function handleClick() {
    onLocationChange(item.route);
    navigate(item.route);
  }

  return (
    <Box
      onClick={handleClick}
      className="flex flex-row justify-start items-center gap-4 relative pl-4 py-1 text-md uppercase font-medium cursor-pointer"
      sx={{
        '::before': {
          position: 'absolute',
          left: '0',
          content: '" "',
          backgroundColor: color,
          height: '100%',
          width: active ? '4px' : '80px',
          opacity: active ? '1' : '0',
          transform: active ? 'translateX(0px)' : 'translateX(80px)',
          transition: '0.3s',
          transitionDelay: '0.05s',
        },
        ':hover::before': {
          opacity: '1',
          width: '4px',
          transform: 'translateX(0px)',
        },
      }}
    >
      {item.label}
    </Box>
  );
};

export default NavItem;
