import { Box } from '@mui/material';
import NavItem from './NavItem';

const NavGroup = ({ group, location, onLocationChange, color }) => {
  return (
    <Box className="flex flex-col gap-1">
      {group.items.map((item, index) => (
        <NavItem
          key={index}
          onLocationChange={onLocationChange}
          item={item}
          active={Boolean(location === item.route)}
          color={color}
        />
      ))}
    </Box>
  );
};

export default NavGroup;
