import { Box, Button, Link, Typography, useTheme } from '@mui/material';
import { useEffect, useRef } from 'react';
import TitlePic from '../../components/TitlePic';
import Person from '../../components/Person';
import contact from '../../global/contact';
import { tokens } from '../../theme/theme';
import Certificats from './Certificats';
import { persons } from '../../global/persons';

const AboutUs = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const aboutUsRef = useRef(null);
  useEffect(() => {
    if (aboutUsRef) aboutUsRef.current.scrollIntoView({ behavior: 'smooth' });
  }, []);
  return (
    <Box ref={aboutUsRef}>
      <TitlePic
        title="Unsere Leidenschaft"
        upperText="Über uns"
        src={{
          webp: '/media/contact/PFLEGER BAUSERVICE CONTACT.webp',
          jpg: '/media/contact/PFLEGER BAUSERVICE CONTACT.jpg',
        }}
      />

      <Box className="mt-8 p-4 md:pl-0 grid grid-cols-1 2xl:grid-cols-2 gap-10 2xl:gap-0">
        {persons.map((person) => (
          <Person
            name={person.name}
            position={person.position}
            qualifications={person.qualifications}
            contact={person.contact}
            src={person.img}
          />
        ))}
      </Box>
      <Certificats />
    </Box>
  );
};

export default AboutUs;
