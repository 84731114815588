import { useEffect, useState } from 'react';

const ImageWithFallback = ({
  webp,
  jpg,
  alt = '',
  className = '',
  sx = {},
  opacity,
}) => {
  const [supportsWebP, setSupportsWebP] = useState(true);

  useEffect(() => {
    checkWebpSupport();
  }, []);

  function checkWebpSupport() {
    const img = new Image();

    img.onload = () => {
      setSupportsWebP(true);
    };

    img.onerror = () => {
      setSupportsWebP(false);
    };

    img.src = webp;
  }

  return (
    <img
      style={{ opacity, ...sx }}
      src={supportsWebP ? webp : jpg}
      alt={alt}
      className={className}
    />
  );
};

export default ImageWithFallback;
