import { Box, useTheme } from '@mui/material';
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
import HeroTitel from '../../../components/HeroTitel';
import ImageWithFallback from '../../../components/ImageWithFallback';
import { tokens } from '../../../theme/theme';

const AutoplaySlider = withAutoplay(AwesomeSlider);

const HeroSektion = ({ onLocationChange }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const sliderItems = [
    {
      title: 'Energieberatung',
      subTitle: 'Energieexperte',
      webp: '/media/hero/PFLEGER_BAUSERVICE_HERO-5.webp',
      jpg: '/media/hero/PFLEGER_BAUSERVICE_HERO-5.jpg',
      color: colors.energy[500],
      buttonLabel: 'mehr erfahren',
      buttonPath: 'energie-experte',
      variant: 'energieberatung',
    },
    {
      title: 'Bau im Griff',
      subTitle: 'Fachbauleitung',
      webp: '/media/hero/PFLEGER_BAUSERVICE_HERO-2.webp',
      jpg: '/media/hero/PFLEGER_BAUSERVICE_HERO-2.jpg',
    },
    {
      title: 'Bauten neu erfinden',
      subTitle: 'Entkernung',
      webp: '/media/hero/PFLEGER_BAUSERVICE_HERO-4.webp',
      jpg: '/media/hero/PFLEGER_BAUSERVICE_HERO-4.jpg',
    },
    {
      title: 'Gebäude im Rückwärtsgang',
      subTitle: 'Abbruch',
      webp: '/media/hero/PFLEGER_BAUSERVICE_HERO-1.webp',
      jpg: '/media/hero/PFLEGER_BAUSERVICE_HERO-1.jpg',
    },
    {
      title: 'Sachkundig beraten',
      subTitle: 'Bausachverständiger',
      webp: '/media/hero/PFLEGER_BAUSERVICE_HERO-3.webp',
      jpg: '/media/hero/PFLEGER_BAUSERVICE_HERO-3.jpg',
    },
  ];

  return (
    <Box
      className="w-full h-full relative"
      sx={{
        '& span': {
          height: '20px',
        },
        '& span::before, span::after': {
          backgroundColor: 'white',
        },
      }}
    >
      <AutoplaySlider
        className="h-full"
        animation="cubeAnimation"
        play={true}
        cancelOnInteraction={false}
        interval={6000}
        bullets={false}
        mobileTouch={true}
      >
        {sliderItems.map(
          (item, index) =>
            item.custom || (
              <Box
                key={index}
                className="flex justify-start items-center w-full h-full"
              >
                <HeroTitel
                  title={item.title}
                  upperText={item.subTitle}
                  color={item.color}
                  buttonPath={item.buttonPath}
                  buttonLabel={item.buttonLabel}
                  onLocationChange={onLocationChange}
                />
                <ImageWithFallback
                  alt={item.title}
                  className=" absolute top-0 left-0 h-full w-full object-cover"
                  webp={item.webp}
                  jpg={item.jpg}
                />
              </Box>
            )
        )}
      </AutoplaySlider>
    </Box>
  );
};

export default HeroSektion;
