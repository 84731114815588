import { Box, Button, useTheme } from '@mui/material';
import { tokens } from '../theme/theme';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { NavLink, useNavigate } from 'react-router-dom';

const HeroTitel = ({
  title,
  upperText,
  color,
  buttonLabel,
  buttonPath,
  onLocationChange,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const navigate = useNavigate();

  function handleClick() {
    navigate(buttonPath);
    onLocationChange(buttonPath);
  }

  return (
    <Box
      className="relative box z-20"
      sx={{ marginBottom: '20vh', marginLeft: '8%' }}
    >
      <Box
        className="relative flex flex-col gap-0"
        sx={{
          background:
            'linear-gradient(90deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.8) 30%, rgba(0,0,0,0) 90%)',

          zIndex: '10',
          color: colors.contrast,
          padding: '3vw 30px',
          textTransform: 'uppercase',
          textShadow: '0px 0px 4px rgba(0, 0, 0, 0.5)',
          maxWidth: '50vw',
          fontSize: '4.3vw',
          lineHeight: '5vw',
          '@media (max-width: 767px)': {
            fontSize: '9vw',
            lineHeight: '9vw',
            marginBottom: '0vh',
            marginTop: '10vh',
            maxWidth: 'unset',
            maxWidth: 'unset',
            padding: '8vw 30px',
          },
          '::before, ::after': {
            width: '90%',
            height: '5px',
            content: '" "',
            backgroundColor: color || colors.highlight[500],
            display: 'block',
            position: 'absolute',
            left: '0',
          },
          '::before': {
            top: '0',
          },
          '::after': {
            bottom: '0',
          },
        }}
      >
        {upperText && (
          <Box
            sx={{
              color: colors.contrast,
              fontSize: '1.5vw',
              lineHeight: '1.5vw',
              textTransform: 'uppercase',
              textShadow: '0px 0px 3px rgba(0, 0, 0, 0.5)',
              '@media (max-width: 767px)': {
                fontSize: '4vw',
                lineHeight: '5vw',
              },
            }}
          >
            {upperText}
          </Box>
        )}
        {title}
      </Box>
      {buttonPath && buttonLabel && (
        <Box className="pt-4 cursor-pointer">
          <Button
            onClick={handleClick}
            variant="text"
            size="large"
            endIcon={<KeyboardArrowRightIcon />}
            sx={{
              bgcolor: 'rgba(255,255,255,0.5)',
              ':hover': {
                bgcolor: 'rgba(255,255,255,0.8)',
              },
            }}
            className=" backdrop-blur-lg"
          >
            {buttonLabel}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default HeroTitel;
