import { Box, Button, Link, Typography, useTheme } from '@mui/material';
import { useEffect, useRef } from 'react';
import TitlePic from '../../components/TitlePic';
import Person from '../../components/Person';
import Times from './Times';
import contact from '../../global/contact';
import map from '../../global/map';
import { tokens } from '../../theme/theme';
import MapIcon from '@mui/icons-material/Map';
import ImageWithFallback from '../../components/ImageWithFallback';
import StepByStep from './StepByStep';

const Contact = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const contactRef = useRef(null);
  useEffect(() => {
    if (contactRef) contactRef.current.scrollIntoView({ behavior: 'smooth' });
  }, []);
  return (
    <Box ref={contactRef}>
      <TitlePic
        title="so gehts"
        upperText="Einfach kontaktieren"
        src={{
          webp: '/media/title/PFLEGER BAUSERVICE TITLE-7.webp',
          jpg: '/media/title/PFLEGER BAUSERVICE TITLE-7.jpg',
        }}
      />

      <Box className="grid grid-cols-1 lg:grid-cols-2">
        <Times />
        <Link
          target="_blank"
          href={map.href}
          sx={{
            height: '400px',
            '@media (max-width: 600px)': {
              height: '300px',
            },
          }}
        >
          <Box className="flex flex-col md:pb-16 md:p-0 gap-5 items-center md:items-start justify-center relative h-full">
            <ImageWithFallback
              alt="map"
              className=" absolute top-0 left-0 w-full h-full object-cover"
              webp={map.webp}
              jpg={map.jpg}
            />

            <Box
              className=" opacity-0 hover:opacity-100 absolute top-0 left-0 h-full w-full z-10 flex flex-col justify-center items-center gap-4 transition-opacity"
              sx={{
                backgroundColor: 'rgba(0, 0, 0, 0.8)',
                color: colors.contrast,
              }}
            >
              <MapIcon sx={{ fontSize: '50px' }} />
              Google Maps öffnen
            </Box>
          </Box>
        </Link>
      </Box>
      <Box className="pt-4">
        <Typography variant="h2" textAlign="left" className="uppercase">
          Kontakt bitte per Mail
        </Typography>
        <Typography variant="h5" textAlign="left">
          Wir bevorzugen die Erstkontaktaufnahme per Mail in folgender Form
        </Typography>
      </Box>
      <StepByStep />
      <Box className="flex justify-center items-center pb-5">
        <Link href={contact[0].href}>
          <Button
            variant="contained"
            sx={{
              color: colors.contrast,
              backgroundColor: colors.highlight[500],
              fontSize: '18px',
            }}
          >
            Jetzt Kontaktieren
          </Button>
        </Link>
      </Box>
    </Box>
  );
};

export default Contact;
