import { Box, useTheme } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { tokens } from '../../theme/theme';
import { HeroSektion } from './sektions/HeroSektion';
import { EnergieBeratung } from './sektions/EnergieBeratung';
import { useLocation } from 'react-router-dom';
import { Sanierungsfahrplan } from './sektions/Sanierungsfahrplan';
import { Baubegleitung } from './sektions/Baubegleitung';
import { Foerdermittel } from './sektions/Foerdermittel';
import { Energieausweis } from './sektions/Energieausweis';

export const EnergieExperte = ({ onLocationChange, onVariantChange }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const heroRef = useRef(null);
  const energieBeratungRef = useRef(null);
  const sanierungsfahrplanRef = useRef(null);
  const baubegleitungRef = useRef(null);
  const foerdermittelRef = useRef(null);
  const energieausweiseRef = useRef(null);

  const scrollElements = [
    {
      to: '/energie-experte',
      ref: heroRef,
      element: <HeroSektion />,
    },
    {
      to: '/energie-beratung',
      ref: energieBeratungRef,
      element: <EnergieBeratung />,
    },
    {
      to: '/sanierungsfahrplan',
      ref: sanierungsfahrplanRef,
      element: <Sanierungsfahrplan />,
    },
    {
      to: '/baubegleitung',
      ref: baubegleitungRef,
      element: <Baubegleitung />,
    },
    {
      to: '/foerdermittel',
      ref: foerdermittelRef,
      element: <Foerdermittel />,
    },

    {
      to: '/energieausweise',
      ref: energieausweiseRef,
      element: <Energieausweis />,
    },
  ];

  function findRefByTo(to) {
    const foundElement = scrollElements.find((element) => element.to === to);
    return foundElement ? foundElement.ref : null;
  }

  const location = useLocation();
  useEffect(() => {
    const targetRef = findRefByTo(location.pathname);
    if (targetRef && targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location]);

  // snapping logic
  useEffect(() => {
    //change the navigation to bauwesen
    onVariantChange('energieberatung');

    const handleScroll = () => {
      // Find the ref that is currently snapping
      let snappingRef = null;
      let minDistance = Number.MAX_VALUE;

      scrollElements.forEach((element) => {
        if (element.ref.current) {
          const boundingRect = element.ref.current.getBoundingClientRect();
          const distance = Math.abs(boundingRect.top);
          if (distance < minDistance) {
            minDistance = distance;
            snappingRef = element.ref;
          }
        }
      });

      // Set the currently snapping ref in state
      onLocationChange(snappingRef.current.getAttribute('to'));
    };

    const main = document.getElementById('main');

    // Attach the scroll event listener
    main.addEventListener('scroll', handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      main.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      {scrollElements.map((element, index) => (
        <Box
          key={index}
          ref={element.ref}
          to={element.to}
          className={
            element.className
              ? element.className
              : 'snap-center min-h-full xl:h-full xl:overflow-y-auto'
          }
        >
          {element.element}
        </Box>
      ))}
    </>
  );
};
