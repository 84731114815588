import { createContext, useState, useMemo } from 'react';
import { createTheme } from '@mui/material/styles';
import calcColorTheme from './CalcColorTheme';
import Cookies from 'universal-cookie';

// color design tokens
export const tokens = (mode, customColorTheme) => {
  const fixedColors = {
    glass: mode === 'dark' ? 'rgba(0, 0, 0, 0.4)' : 'rgba(250, 250, 250, 0.4)',
    card: mode === 'dark' ? 'rgba(50, 50, 50)' : 'rgb(240, 240, 240)',
    contrast: mode === 'dark' ? 'rgba(0, 0, 0, 1)' : 'rgba(250, 250, 250, 1)',
    invContrast:
      mode === 'light' ? 'rgba(0, 0, 0, 1)' : 'rgba(250, 250, 250, 1)',
    money:
      mode === 'dark'
        ? {
            positive: '#5bc989',
            negative: '#b55555',
            neutral: '#D3D3D3',
          }
        : {
            positive: '#24854c',
            negative: '#852424',
            neutral: '#D3D3D3',
          },
    colorPool: {
      0: '#00A5E3', //blue
      1: '#8DD7BF', //green
      2: '#FF96C5', //pink
      3: '#FF5768', //red
      4: '#FFA23A', //orange
      5: '#FFD872', //yellow
      6: '#CFF800', //lime
      7: '#E7C582', //brown
      8: '#5a5d6e', //gray
    },
  };

  return { ...fixedColors, ...customColorTheme };
};

//mui theme settings
export const themeSettings = (mode, customColorTheme) => {
  const colors = tokens(mode, customColorTheme);

  return {
    components: {
      MuiTab: {
        styleOverrides: {
          root: {
            color: colors.grey[800],
            '&.Mui-selected': {
              backgroundColor: colors.glass,
              color: colors.primary[500],
            },
          },
        },
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            '& label, & label.Mui-focused': {
              color: colors.grey[800],
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            '&.MuiOutlinedInput-root': {
              backgroundColor: colors.glass,
            },
            borderRadius: '10px',
            '& label.Mui-focused': {
              color: colors.primary[800],
            },
            '& input::-webkit-inner-spin-button': {
              appearance: 'none',
              margin: '0',
            },
            '& p.Mui-error': {
              height: '0',
              margin: '0',
            },
            '& fieldset': {
              borderWidth: '1px',
              borderColor: colors.primary[300],
              borderRadius: '10px',
            },
            '& .Mui-focused>fieldset': {
              borderWidth: '1px!important',
              borderColor: colors.primary[800] + '!important',
              borderRadius: '10px',
            },
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            borderRadius: '10px',
            '& label.Mui-focused': {
              color: colors.primary[800],
            },
            '& input::-webkit-inner-spin-button': {
              appearance: 'none',
              margin: '0',
            },
            '& p.Mui-error': {
              height: '0',
              margin: '0',
            },
            '& fieldset': {
              borderWidth: '1px',
              borderColor: colors.primary[300],
              borderRadius: '10px',
            },
            '& .Mui-focused>fieldset': {
              borderWidth: '1px!important',
              borderColor: colors.primary[800] + '!important',
              borderRadius: '10px',
            },
          },
        },
      },
      MuiList: {
        styleOverrides: {
          root: {
            '& li:hover': {
              backgroundColor: colors.primary[300],
            },
            '& li.Mui-selected': {
              backgroundColor: colors.primary[400],
            },
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            borderRadius: '10px',
            backgroundColor: colors.glass,
            // Apply the same styles as MuiTextField
            '& label.Mui-focused': {
              color: colors.grey[800] + '!important',
            },
            '& input::-webkit-inner-spin-button': {
              appearance: 'none',
              margin: '0',
            },
            '& p.Mui-error': {
              height: '0',
              margin: '0',
            },
            '& fieldset': {
              borderWidth: '1px',
              borderColor: colors.primary[300],
              borderRadius: '10px',
            },
            '& .Mui-focused>fieldset': {
              borderWidth: '1px!important',
              borderColor: colors.primary[500] + '!important',
              borderRadius: '10px',
            },
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          root: {
            backdropFilter: 'blur(15px)',
            '& .MuiDialog-paper': {
              backgroundImage: 'unset!important',
              overflow: 'auto',
              borderRadius: '15px',
              maxWidth: '100%',
              minWidth: '350px',
              maxHeight: '90vh',
              margin: '0px',
              width: 'fit-content',
              backgroundColor: colors.card,
              '@media (max-width: 900px)': {
                maxHeight: '100vh',
              },
            },
          },
        },
      },
      MuiBackdrop: {
        styleOverrides: {
          root: {
            backdropFilter: 'blur(5px)',
            backgroundColor: colors.glass,
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: '10px',
          },
          outlined: {
            borderColor: colors.primary[500],
            color: colors.primary[500],
          },
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            '&.Mui-expanded .MuiAccordionSummary-root  ': {
              backgroundColor: 'transparent!important',
            },
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundColor: colors.card,
          },
        },
      },
      MuiStepper: {
        styleOverrides: {
          root: {
            '& .Mui-active': {
              '&.MuiSvgIcon-root': {
                color: colors.blueAccent[500],
              },
              '& .MuiStepConnector-line': {
                borderColor: colors.blueAccent[500],
              },
            },
            '& .Mui-completed': {
              '&.MuiSvgIcon-root': {
                color: colors.blueAccent[500],
              },
              '&.MuiStepLabel-label': {
                color: colors.grey[900] + '!Important',
              },
              '& .MuiStepConnector-line': {
                borderColor: colors.grey[400],
              },
            },
          },
        },
      },
    },
    palette: {
      colorTheme: customColorTheme,
      mode: mode,
      ...{
        primary: {
          main: colors.primary[900],
          light: colors.primary[600],
          dark: colors.primary[900],
          contrastText: colors.grey[900],
        },
        secondary: {
          main: colors.highlight[300],
        },
        neutral: {
          dark: colors.grey[700],
          main: colors.grey[700],
          light: colors.grey[700],
        },
        background: {
          paper: colors.primary[100],
          default: colors.contrast,
        },
        table: {
          default: colors.primary[200],
        },
      },
    },
    typography: {
      fontFamily: 'Roboto',
      fontSize: 12,
      h1: {
        fontFamily: 'Roboto',
        fontSize: 40,
      },
      h2: {
        fontFamily: 'Roboto',
        fontSize: 32,
        fontWeight: '400',
      },
      h3: {
        fontFamily: 'Roboto',
        fontSize: 24,
      },
      h4: {
        fontFamily: 'Roboto',
        fontSize: 16,
        textTransform: 'uppercase',
        textAlign: 'justify',
        fontWeight: '400',
      },
      h5: {
        fontFamily: 'Roboto',
        fontSize: 16,
      },
      h6: {
        fontFamily: 'Roboto',
        fontSize: 14,
        color: colors.grey[500],
        fontWeight: 400,
        textTransform: 'uppercase',
      },
      p: {
        fontFamily: 'Roboto',
        fontSize: 14,
      },
    },
  };
};

//context for color mode
export const ColorModeContext = createContext({
  toggleColorMode: () => {},
});

export const useMode = () => {
  const cookies = new Cookies();

  // color mode
  const [mode, setMode] = useState(
    cookies.get('colorMode') ? cookies.get('colorMode') : 'light'
  );
  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prev) => {
          const newMode = prev === 'light' ? 'dark' : 'light';
          updateTheme(newMode);
          return newMode;
        });
      },
    }),
    []
  );

  // color theme
  var storedColors;
  storedColors = {
    primary: '#434750',
    highlight: '#d43131',
    grey: '#5a5d6e',
    greenAccent: '#a6bb22',
    redAccent: '#b94646',
    blueAccent: '#3273bc',
    energy: '#97B58F',
  };
  /*
  if (cookies.get("colorTheme") !== "EMPTY") {
    storedColors = cookies.get("colorTheme");
  }
*/
  const [colorTheme, setColorTheme] = useState(
    calcColorTheme(storedColors, mode)
  );

  function updateTheme(newMode) {
    setColorTheme(calcColorTheme(storedColors, newMode));
  }

  // create theme
  const theme = useMemo(
    () => createTheme(themeSettings(mode, colorTheme)),
    [mode]
  );

  // change Theme Settings

  return [theme, colorMode];
};
