import React, { useEffect } from 'react';

export const Analytics = ({ src, websiteId }) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = src;
    script.defer = true;
    script.setAttribute('data-website-id', websiteId);

    document.body.appendChild(script);

    return () => {
      // Clean up the script when the component unmounts
      document.body.removeChild(script);
    };
  }, []);

  return null; // This component does not render any visible content
};
