import { Box, Typography } from '@mui/material';
import LocalPhoneTwoToneIcon from '@mui/icons-material/LocalPhoneTwoTone';
import EmailTwoToneIcon from '@mui/icons-material/EmailTwoTone';
import LinkIcon from '@mui/icons-material/Link';

export const persons = [
  {
    img: {
      webp: '/media/contact/PFLEGER BAUSERVICE LANCELOT.webp',
      jpg: '/media/contact/PFLEGER BAUSERVICE LANCELOT.jpg',
    },
    name: 'Lancelot Pfleger',
    position: 'Geschäftsführer',
    qualifications: [],
    contact: [
      {
        label: 'info@pfleger-bau.de',
        href: 'mailto:info@pfleger-bau.de',
        icon: <EmailTwoToneIcon />,
        type: 'iconLink',
      },
      {
        label: '+49151 59 1818 59',
        href: 'tel:+4915159181859',
        icon: <LocalPhoneTwoToneIcon />,
        type: 'iconLink',
      },
    ],
    aboutUs: (
      <Box>
        <Typography textAlign="justify">
          Nach meiner Wehrdienstzeit im Jahre 2008 in der Klotzberg-Kaserne in
          Idar-Oberstein habe ich mich dazu entschieden, eine Lehre zum
          Dachdeckergesellen in München zu absolvieren. Diese absolvierte ich
          erfolgreich und wurde dementsprechend in der Region "Oberbayern" auf
          einem Podiumsplatz ausgezeichnet, worauf ich heute noch sehr Stolz
          bin. Nach ein paar Jahren in der Praxis und dem bis dato angesammelten
          Wissen, entschloss ich mich im Jahre 2015 für die Ausbildung zum
          staatlich geprüften Hochbautechniker an der MHK in Kaiserslautern.
        </Typography>
        <Typography textAlign="justify">
          Auch diese Ausbildung schloss ich mit Erfolg ab.
        </Typography>
        <Typography textAlign="justify">
          Darauf aufbauend sammelte ich im Anschluss Erfahrungen in der
          operativen Bauleitung in den Bereichen Erdbau, Rückbau, Hochbau,
          Schlüsselfertigbau sowie der Schadstoffsanierung. Im Zuge meiner
          aktiven Bauleitungszeit in Anstellung bei den zuvor genannten
          Fachbereichen, merkte ich, dass mir insbesondere das Themengebiet
          "Schadstoffe" und "Abbruch" Freude bereitet und dementsprechend eine
          fachkompetente Beratung sowie die Gesamtabwicklung der Projekte leicht
          fallen. Um meine, in diesem Zeitraum entstandene, Wissbegierde zu
          stillen, absolvierte ich berufsbegleitend diverse Fortbildungen um die
          fachbezogene Lizenzen wie z.B. die TRGS 519, 521, 524, Koordinator
          nach DGUV 101-004 Anlage 6a+6b, ehem. BGR128, SiGeKo nach RAB 30,
          Schimmelpilze und BiostoffVerordnung, nachweisen zu können, bevor ich
          im Jahre 2021 den Schritt in die Selbstständikeit wagte. Fortan war
          dies nicht mein Beruf sondern meine Berufung.
        </Typography>
        <Typography textAlign="justify">
          Zu guter Letzt durchlief ich, um meine Klienten exklusiv und
          fachkompetent zu beraten, die Weiterbildung zum geprüften
          Bausachverständiger für die Bewertung von Schäden an und in Gebäuden
          (VfB) sowie den geprüften Baugutachter Werteermittlung.
        </Typography>
        <Typography textAlign="justify">
          Meine Passion ist es, dass speziell das Themengebiet der Schadstoffe,
          welche in meinem Tätigkeitsbereich fast überall auftreten, den
          Fachfremden bzw. Laien, verständlich zu machen, diese hierfür zu
          sensibilisiern und entsprechend, auch aus rechtlicher Sicht, beratend
          zur Seite zu stehen.
        </Typography>
      </Box>
    ),
  },
  {
    img: {
      webp: '/media/contact/PFLEGER BAUSERVICE PHILIPP.webp',
      jpg: '/media/contact/PFLEGER BAUSERVICE PHILIPP.jpg',
    },
    name: 'Philipp Engel',
    position: 'Partner in Baden-Württemberg',
    qualifications: [],
    contact: [
      {
        label: 'www.enertech-beratung.de',
        href: 'https://www.enertech-beratung.de',
        icon: <LinkIcon />,
        type: 'iconLink',
      },
      {
        label: 'info@enertech-beratung.de',
        href: 'mailto:info @ enertech-beratung.de',
        icon: <EmailTwoToneIcon />,
        type: 'iconLink',
      },
      {
        label: '+497621 706 440 2',
        href: 'tel:+4976217064402',
        icon: <LocalPhoneTwoToneIcon />,
        type: 'iconLink',
      },
    ],
    aboutUs: (
      <Box>
        <Typography textAlign="justify"></Typography>
      </Box>
    ),
  },
];
