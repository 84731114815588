import { Box } from '@mui/system';
import InfoTitle from './InfoTitle';
import InfoBullet from './InfoBullet';

const InfoSection = ({
  title,
  mobileTitle,
  subTitle,
  number,
  bulletArray = ['demo1', 'demo2'],
  color,
}) => {
  return (
    <Box
      className=" flex flex-col gap-4 pt-20 pb-10 lg:py-10"
      sx={{
        maxWidth: '100%',
      }}
    >
      <InfoTitle
        title={title}
        subTitle={subTitle}
        number={number}
        mobileTitle={mobileTitle}
        color={color}
      />
      <Box className=" flex flex-col gap-2 pl-6">
        {bulletArray.map((item, index) => (
          <InfoBullet key={index} text={item} />
        ))}
      </Box>
    </Box>
  );
};

export default InfoSection;
