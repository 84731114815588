import { Box, useTheme } from '@mui/material';
import React from 'react';
import HeroTitel from '../../../components/HeroTitel';
import { tokens } from '../../../theme/theme';
import LoopingVideo from '../LoopingVideo';

export const HeroSektion = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  return (
    <Box className="relative h-[100vh] w-full flex items-center ">
      <HeroTitel
        title="Weniger Kosten. Mehr Wohnkomfort."
        upperText="Energieberatung"
        color={colors.energy[500]}
      />
      <Box className="w-full h-full flex overflow-hidden absolute top-0 left-0">
        <LoopingVideo src={'/media/hero/PFLEGER_BAUSERVICE_HERO_ENERGY.mp4'} />
      </Box>
    </Box>
  );
};
